import { createApp } from 'vue';
import Menu from './components/Menu.vue';
import Featured from './components/Featured.vue';

const vueComponents = {};

async function fetchCsrfToken() {
  try {
    const response = await fetch('/actions/blitz/csrf/token');
    return await response.text();
  } catch (error) {
    console.error('Failed to fetch CSRF token:', error);
    return null;
  }
}

async function initVueComponents() {
  const csrfToken = await fetchCsrfToken();

  const components = [
    { selector: '[data-vue-menu]', component: Menu },
    { selector: '[data-vue-featured]', component: Featured }
  ];

  components.forEach(({ selector, component }) => {
    const element = document.querySelector(selector);
    if (element) {
      createApp(component, { content: element.innerHTML })
        .provide('csrfToken', csrfToken)
        .mount(element);
    }
  });
}

document.addEventListener('vueComponentMounted', (event) => {
  const { id, methods } = event.detail;
  vueComponents[id] = methods;
});

window.vueComponentAction = (id, action) => {
  if (vueComponents[id]?.[action]?.call) {
    vueComponents[id][action]();
  } else {
    console.error(`Action ${action} not found for component ${id}`);
  }
};

document.addEventListener('DOMContentLoaded', initVueComponents);

export { initVueComponents };
